import * as React from "react"

import Lottie from 'lottie-react';

import { useInView } from 'react-intersection-observer';

import Clients from "../../assets/Client Logos.json";

const ClientSection = (props) => {
    const [ref, inView] = useInView({
        threshold: 0, // Trigger animation when the div is 50% visible
        triggerOnce: true,
        delay: 1.5
    });
  return (
    <section className="client-section panel">
        <div className="container">
            <div className="row" >
                <div className="col-lg-12">
                        <h2 className="h2 section-title extra-spacing clients-heading-reveal">{props.mainTitle}</h2>
                    {/* <SlideUp duration={400} delay={500}>
                    </SlideUp> */}
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-11">
                        <p className="section-description clients-para-reveal">{props.mainDescription}</p>
                    {/* <SlideUp duration={400} delay={500}>
                    </SlideUp> */}
                </div>
            </div>
            {/* <div className="row">
                <div className="col-lg-13">
                    <p className="section-description">{props.mainDescription}</p>
                </div>
            </div> */}
            <div className="client-items">
                {/* <div className="row">
                    {props.list?.map((object, i) => {
                        return (
                            <div className="col-8 col-md-6 col-lg-2" key={shortid.generate()}>
                                <div className="item">
                                    <div className="logo">
                                        <img
                                            src={object.image?.sourceUrl}
                                            alt={object.image?.altText}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div> */}
                {/* <ScaleUp duration={300} delay={500}> */}
                    <div className="image-block" ref={ref}>
                        {/* <img
                            src={props.clients?.sourceUrl}
                            alt={props.clients?.altText}
                            width={props.clients?.width}
                        /> */}
                        {inView && (
                            <Lottie 
                            animationData={Clients}
                            loop={true}
                            height={100}
                        />
                        )}
                    </div>
                {/* </ScaleUp> */}
                {/* <div className="mobile-image-block">
                    <img 
                        src={Clients}
                        alt="clients"
                    />
                </div> */}
            </div>
        </div>
    </section>
  )
}

export default ClientSection